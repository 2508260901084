export const orderDays = (data) => {
  const diasOrdenados = [
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
  ]
  return diasOrdenados.reduce((resultado, dia) => {
    if (data[dia]) resultado[dia] = data[dia]
    return resultado
  }, {})
}

export const getSueldoPDiaToString = (data) => {
  if (data === '0') {
    return 'Sí'
  } else {
    return 'No'
  }
}

export const getAreaToString = (data) => {
  switch (data) {
    case '1':
      return 'PRODUCCIÓN'
    case '2':
      return 'PALETIZADO'
    case '3':
      return 'ENTREGAS'
    case '4':
      return 'DESPACHO'
    case '5':
      return 'VENTAS'
    case '6':
      return 'ADMINISTRATIVA'
    default:
      return 'error'
  }
}
