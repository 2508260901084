import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Form } from '../../UI/Form'
import { styled } from '@mui/system'
import { Calculate } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  getRetencionesById,
  getSelectBonos,
  selectSalesWorkers,
} from '../../../helpers/workersFunctions'
import {
  calculateSalesSalaries,
  checkStatus,
  getStatus,
} from '../../../helpers/nominaFunctions'
import { createSalesNomina } from '../../../services/nomina'
import { salesReport } from '../../../services/reports'

const Subtitle = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1.2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const GenerateButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: 'orange',
  border: '1px solid darkorange',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: 'darkorange',
    border: '1px solid orange',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const BonoSelect = styled((props) => <Select {...props} />)(() => ({
  width: '100%',
  backgroundColor: '#e9e9e9',
  borderRadius: '20px',
  height: '38px',
  border: '1px solid rgba(201, 201, 201, 0.711)',
  paddingLeft: '20px',
  fontSize: '14px',
  fontFamily: 'Nunito',
  '&:focus': {
    backgroundColor: 'transparent', // Esto elimina el color de fondo cuando está seleccionado
    border: 'none', // También puedes eliminar el borde si es necesario
  },
}))

const ReportButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: '#00544e',
  border: '1px solid #009696',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: '#009696',
    border: '1px solid #00544e',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const trabajador = {
  diasLaborados: '',
  horasExtra: '',
  salarioSemanal: '',
  salarioNeto: '',
  trabajador: {
    id: '',
    nombre: '',
  },
  bonos: [],
  comisiones: '',
}

const SalesForm = ({ setObject, object, setOpenPopup, setId, id }) => {
  let workers = selectSalesWorkers()

  const [tempObject, setTempObject] = useState({
    ...object,
    vendedores:
      object.vendedores && Object.keys(object.vendedores).length > 0
        ? object.vendedores
        : {},
  })
  const [status, setStatus] = useState(false)

  useEffect(() => {
    setStatus(checkStatus(getStatus(id)))
    // Validamos si `object` está vacío.
    const isObjectEmpty = !object || Object.keys(object).length === 0

    if (isObjectEmpty) {
      // Si `object` está vacío, inicializamos todo en blanco.
      const vendedores = workers.reduce((acc, worker, index) => {
        acc[index + 1] = {
          ...trabajador,
          trabajador: {
            id: worker.id,
            nombre: worker.title,
          },
        }
        return acc
      }, {})

      setTempObject({
        comisiones: {
          cpc30r: '',
          mort: '',
          block: '',
          tabicon: '',
          poste: '',
        },
        vendedores,
      })
    } else if (
      Object.keys(tempObject.vendedores).length === 0 &&
      workers.length > 0
    ) {
      // Si `object` tiene valores, pero falta inicializar vendedores.
      const vendedores = workers.reduce((acc, worker, index) => {
        acc[index + 1] = {
          ...trabajador,
          trabajador: {
            id: worker.id,
            nombre: worker.title,
          },
        }
        return acc
      }, {})

      setTempObject((prev) => ({
        ...prev,
        vendedores,
      }))
    }

    //console.log(workers)
  }, [object, workers, id]) // Quitamos `object` de las dependencias

  const resetData = () => {
    setId('')
    setObject(object)
  }

  const handleGenerateSalary = (workerData, index) => {
    try {
      // 1. Validaciones previas para asegurar que `workerData` es válido
      if (!workerData || !workerData.trabajador || !workerData.trabajador.id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Datos del trabajador incompletos o inválidos.',
        })
        return
      }

      // Validación de que `id` está presente
      if (!id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ID de la nómina no disponible.',
        })
        return
      }

      // Crear una copia profunda de `workerData` para evitar referencias compartidas
      const workerDataCopy = JSON.parse(JSON.stringify(workerData))

      // 2. Llamada a `calculateDriverSalaries` con `workerData` e `id`
      const updatedWorkerData = calculateSalesSalaries(
        workerDataCopy,
        tempObject.comisiones
      )

      // Validación de que `calculateDriverSalaries` ha retornado datos válidos
      if (
        !updatedWorkerData ||
        typeof updatedWorkerData.salarioSemanal === 'undefined'
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error en el cálculo del salario. Intenta nuevamente.',
        })
        return
      }

      setTempObject((prevTempObject) => {
        // Actualizamos la información del trabajador específico
        const updated = {
          ...prevTempObject,
          vendedores: {
            ...prevTempObject.vendedores, // Mantén los demás trabajadores sin cambios
            [index]: {
              ...prevTempObject.vendedores[index], // Mantén los datos previos del trabajador
              ...updatedWorkerData, // Actualiza solo con los nuevos datos
            },
          },
        }
        return updated // Retornamos el objeto actualizado
      })

      // Mensaje de éxito si todo ha salido bien
      Swal.fire({
        icon: 'success',
        title: 'Salario Generado',
        text: `Salario calculado exitosamente para ${workerData.trabajador.nombre}.`,
      })
    } catch (error) {
      // 4. Manejo de errores
      Swal.fire({
        icon: 'error',
        title: 'Error inesperado',
        text: `Ocurrió un error: ${error.message}.`,
      })
      console.error('Error en handleGenerateSalary:', error)
    }
    workerData = null
  }

  const handleGenerateReport = () => {
    salesReport(tempObject, id)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createSalesNomina(tempObject, id)

    resetData()
    Swal.fire('Hecho', 'Area actualizada con éxito', 'success')
    setOpenPopup(false)
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item container spacing={1}>
            <Grid item md={12}>
              <Subtitle>Comisiones Semanales</Subtitle>
            </Grid>

            <Grid container item md={12}>
              <Grid item md={1}>
                <NormalText>Cemento</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`cpc30r`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.cpc30r}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        cpc30r: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Mortero</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`mort`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.mort}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        mort: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Varilla 3/8</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`var38`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.var38}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        var38: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Block</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`block`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.block}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        block: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Tabicon</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`tabicon`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.tabicon}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        tabicon: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Poste</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`poste`}
                  type="number"
                  min={0}
                  value={tempObject.comisiones.poste}
                  onChange={(e) => {
                    // Actualiza el estado aquí con el nuevo valor
                    const newValue = e.target.value
                    setTempObject((prevState) => ({
                      ...prevState,
                      comisiones: {
                        ...prevState.comisiones,
                        poste: newValue,
                      },
                    }))
                  }}
                  disabled={status}
                />
              </Grid>
            </Grid>
          </Grid>
          {Object.values(tempObject.vendedores).map((worker, index) => (
            <Grid container item md={12} spacing={1} key={worker.trabajador.id}>
              <Grid item md={12}>
                <Subtitle>Vendedor {worker.trabajador.nombre}</Subtitle>
              </Grid>

              <Grid item md={1}>
                <NormalText>Días Laborados</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`diasLaborados-${index}`}
                  type="number"
                  min={0}
                  max={6}
                  value={worker.diasLaborados}
                  onChange={(e) => {
                    const newValue = e.target.value

                    setTempObject((prevState) => ({
                      ...prevState,
                      vendedores: Object.values(prevState.vendedores).map(
                        (w, i) =>
                          i === index ? { ...w, diasLaborados: newValue } : w
                      ),
                    }))
                  }}
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Horas Extra</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`horasExtra-${index}`}
                  type="number"
                  max={10}
                  value={worker.horasExtra}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const newValue = e.target.value

                      setTempObject((prevState) => ({
                        ...prevState,
                        vendedores: Object.values(prevState.vendedores).map(
                          (w, i) =>
                            i === index ? { ...w, horasExtra: newValue } : w
                        ),
                      }))
                    })
                  }
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Bonos</NormalText>
              </Grid>
              <Grid item md={3}>
                <BonoSelect
                  name={`bonos-${index}`}
                  multiple
                  value={worker.bonos || []}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const { value } = e.target // Extrae el value del evento
                      const newValue =
                        typeof value === 'string' ? value.split(',') : value // Asegúrate de que siempre sea un array

                      setTempObject((prevState) => ({
                        ...prevState,
                        vendedores: Object.values(prevState.vendedores).map(
                          (w, i) =>
                            i === index ? { ...w, bonos: newValue } : w
                        ),
                      }))
                    })
                  }
                  disabled={status}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {getSelectBonos(worker.trabajador.id).map((item) => (
                    <MenuItem key={item.id} value={item.title}>
                      <Checkbox
                        checked={
                          Array.isArray(worker.bonos) &&
                          worker.bonos.indexOf(item.title) > -1
                        }
                      />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
                </BonoSelect>
              </Grid>

              {/* Salarios */}
              <Grid container item md={12}>
                {/** */}
                <Grid item md={6} />
                <Grid item md={3}>
                  <NormalText>Salario Semanal Sin Comisiones</NormalText>
                </Grid>
                <Grid item md={1}>
                  <NormalText>=</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>
                    ${worker.salarioSemanal - worker.comisiones || '0.00'}
                  </NormalText>
                </Grid>
                {/** */}
                <Grid item md={6} />
                <Grid item md={3}>
                  <NormalText>Comisiones Semanales</NormalText>
                </Grid>
                <Grid item md={1}>
                  <NormalText>=</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>$+{worker.comisiones || '0.00'}</NormalText>
                </Grid>
                {/** */}
                <Grid item md={6} />
                <Grid item md={3}>
                  <NormalText>Salario Semanal Con Comisiones</NormalText>
                </Grid>
                <Grid item md={1}>
                  <NormalText>=</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>${worker.salarioSemanal || '0.00'}</NormalText>
                </Grid>
                {/** */}
                <Grid item md={6} />
                <Grid item md={3}>
                  <NormalText>Retenciones</NormalText>
                </Grid>
                <Grid item md={1}>
                  <NormalText>=</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>
                    ${getRetencionesById(worker.trabajador.id) || '0.00'}
                  </NormalText>
                </Grid>
                {/** */}
                <Grid item md={6} />
                <Grid item md={3}>
                  <NormalText>Salario Semanal Neto</NormalText>
                </Grid>
                <Grid item md={1}>
                  <NormalText>=</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>${worker.salarioNeto || '0.00'}</NormalText>
                </Grid>
              </Grid>

              <Grid item md={8} />
              <Grid item md={4}>
                <GenerateButton
                  variant="contained"
                  startIcon={<Calculate />}
                  onClick={() => {
                    handleGenerateSalary(worker, index)
                  }}
                  disabled={status}
                >
                  Generar Salario
                </GenerateButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {!status ? (
          <input
            id="submit"
            className="btn"
            type="submit"
            name="submit"
            disabled={status}
          />
        ) : (
          <Grid container spacing={1}>
            <Grid item md={12} onClick={() => handleGenerateReport()}>
              <ReportButton>Generar Reporte General de Area</ReportButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </div>
  )
}

export default SalesForm
