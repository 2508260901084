import { getPay } from '../services/cash'
import { FetchServices, getServices } from '../services/services'

export const ticketSelectCashRegister = () => {
  const [tickets] = FetchServices()
  const filterOnlyTicketsNonPaid = tickets.filter(
    (ticket) => ticket.estado === 'A crédito' && ticket.estado !== 'Cancelada'
  )

  let selectOptionTickets = []
  filterOnlyTicketsNonPaid.forEach(function ({ id, folio, cliente }) {
    const dataSelect = {
      id,
      title: `${folio} - ${cliente}`,
    }
    selectOptionTickets.push(dataSelect)
  })
  return selectOptionTickets
}

export const showTicketNumber = (id) => {
  const tickets = getServices()
  const filterOnlyTicket = tickets.find((ticket) => ticket.id === id)
  return filterOnlyTicket ? filterOnlyTicket.folio : undefined
}

const normalizeDate = (dateStr) => {
  // Convertimos la cadena en formato dd/mm/yyyy a un objeto Date
  const [day, month, year] = dateStr.split('/').map(Number)
  const date = new Date(year, month - 1, day) // Los meses en JavaScript son base 0
  date.setHours(0, 0, 0, 0) // Ajustamos la hora a medianoche
  return date
}

export const filterCountTickets = (initialDate, finalDate) => {
  const tickets = getPay()

  initialDate.setHours(0, 0, 0, 0)
  finalDate.setHours(0, 0, 0, 0)

  const filteredTickets = tickets.filter((ticket) => {
    const ticketDate = normalizeDate(ticket.fecha)
    return ticketDate >= initialDate && ticketDate <= finalDate
  })

  filteredTickets.forEach((item) => {
    item.ticket = showTicketNumber(item.ticket)
  })

  return filteredTickets
}
