import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Form } from '../../UI/Form'
import { styled } from '@mui/system'
import { Calculate } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  getPuestoById,
  getRetencionesById,
  getSelectBonos,
  selectLogisticWorkers,
} from '../../../helpers/workersFunctions'
import {
  checkStatus,
  getComisionsLogisticWorker,
  getDeliveriesByDriver,
  getStatus,
} from '../../../helpers/nominaFunctions'
import { createLogisticNomina } from '../../../services/nomina'
import { logisticReport } from '../../../services/reports'

const Subtitle = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1.2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const GenerateButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: 'orange',
  border: '1px solid darkorange',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: 'darkorange',
    border: '1px solid orange',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const BonoSelect = styled((props) => <Select {...props} />)(() => ({
  width: '100%',
  backgroundColor: '#e9e9e9',
  borderRadius: '20px',
  height: '38px',
  border: '1px solid rgba(201, 201, 201, 0.711)',
  paddingLeft: '20px',
  fontSize: '14px',
  fontFamily: 'Nunito',
  '&:focus': {
    backgroundColor: 'transparent', // Esto elimina el color de fondo cuando está seleccionado
    border: 'none', // También puedes eliminar el borde si es necesario
  },
}))

const ReportButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: '#00544e',
  border: '1px solid #009696',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: '#009696',
    border: '1px solid #00544e',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const trabajador = {
  diasLaborados: '',
  pzsCargadas: '',
  horasExtra: '',
  salarioSemanal: '',
  salarioNeto: '',
  trabajador: {
    id: '',
    nombre: '',
  },
  bonos: [],
  viajes: {
    kenworth: '',
    camion: '',
    doble: '',
    nissan: '',
  },
}

const LogisticForm = ({ setObject, object, setOpenPopup, setId, id }) => {
  let workers = selectLogisticWorkers()

  const [tempObject, setTempObject] = useState([])
  const [status, setStatus] = useState(false)

  useEffect(() => {
    setStatus(checkStatus(getStatus(id)))
    if (object && tempObject.length === 0) {
      setTempObject(object)
    } else if (tempObject.length === 0) {
      const initialData = workers.map((worker) => ({
        ...trabajador,
        trabajador: {
          id: worker.id,
          nombre: worker.title,
        },
      }))
      setTempObject(initialData)
    }
  }, [object, workers])

  const resetData = () => {
    setId('')
    setObject(object)
  }

  const handleGenerateSalary = (workerData, index) => {
    try {
      // 1. Validaciones previas para asegurar que `workerData` es válido
      if (!workerData || !workerData.trabajador || !workerData.trabajador.id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Datos del trabajador incompletos o inválidos.',
        })
        return
      }

      // Validación de que `id` está presente
      if (!id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ID de la nómina no disponible.',
        })
        return
      }

      // Crear una copia profunda de `workerData` para evitar referencias compartidas
      const workerDataCopy = JSON.parse(JSON.stringify(workerData))

      // 2. Llamada a `calculateDriverSalaries` con `workerData` e `id`
      const updatedWorkerData = getDeliveriesByDriver(workerDataCopy, id)

      // Validación de que `calculateDriverSalaries` ha retornado datos válidos
      if (
        !updatedWorkerData ||
        typeof updatedWorkerData.salarioSemanal === 'undefined'
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error en el cálculo del salario. Intenta nuevamente.',
        })
        return
      }

      // 3. Actualización de `tempObject` con el nodo específico
      setTempObject((prevTempObject) => {
        const updated = prevTempObject.map((item) =>
          item.trabajador.id === workerData.trabajador.id
            ? { ...item, ...updatedWorkerData }
            : item
        )
        return [...updated] // Asegúrate de retornar una nueva referencia del arreglo
      })

      // Mensaje de éxito si todo ha salido bien
      Swal.fire({
        icon: 'success',
        title: 'Salario Generado',
        text: `Salario calculado exitosamente para ${workerData.trabajador.nombre}.`,
      })
    } catch (error) {
      // 4. Manejo de errores
      Swal.fire({
        icon: 'error',
        title: 'Error inesperado',
        text: `Ocurrió un error: ${error.message}.`,
      })
      console.error('Error en handleGenerateSalary:', error)
    }

    workerData = null
  }

  const handleGenerateReport = () => {
    logisticReport(tempObject, id)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createLogisticNomina(tempObject, id)

    resetData()
    Swal.fire('Hecho', 'Area actualizada con éxito', 'success')
    setOpenPopup(false)
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {tempObject.map((worker, index) => (
            <Grid container item md={12} spacing={1} key={worker.trabajador.id}>
              <Grid item md={12}>
                <Subtitle>Trabajador {worker.trabajador.nombre}</Subtitle>
              </Grid>

              <Grid item md={1}>
                <NormalText>Días Laborados</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`diasLaborados-${index}`}
                  type="number"
                  min={0}
                  max={6}
                  value={worker.diasLaborados}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].diasLaborados = e.target.value
                      return updated
                    })
                  }
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Horas Extra</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`horasExtra-${index}`}
                  type="number"
                  max={20}
                  value={worker.horasExtra}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].horasExtra = e.target.value
                      return updated
                    })
                  }
                  disabled={status}
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Bonos</NormalText>
              </Grid>
              <Grid item md={3}>
                <BonoSelect
                  name={`bonos-${index}`}
                  multiple
                  value={worker.bonos || []}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].bonos =
                        typeof value === 'string' ? value.split(',') : value
                      return updated
                    })
                  }}
                  disabled={status}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {getSelectBonos(worker.trabajador.id).map((item) => (
                    <MenuItem key={item.id} value={item.title}>
                      <Checkbox
                        checked={
                          Array.isArray(worker.bonos) &&
                          worker.bonos.indexOf(item.title) > -1
                        }
                      />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
                </BonoSelect>
              </Grid>

              <Grid container item md={12}>
                {/* Número de viajes */}
                <Grid item md={2}>
                  <NormalText>{`Viajes Kenworth: ${
                    worker.viajes.kenworth || 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={3}>
                  <NormalText>{`Viajes Inter/Ford: ${
                    worker.viajes.camion || 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>{`Viajes Ford 2014: ${
                    worker.viajes.doble || 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>{`Viajes Nissan: ${
                    worker.viajes.nissan || 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={3}>
                  <NormalText>{`Piezas Cargadas: ${
                    worker.pzsCargadas || 0
                  }`}</NormalText>
                </Grid>
                {/* Comisión de viajes */}
                <Grid item md={2}>
                  <NormalText>{`Comisión Kenworth: $${
                    getPuestoById(worker.trabajador.id) === '8'
                      ? getComisionsLogisticWorker(
                          worker.trabajador.id,
                          worker.viajes.kenworth,
                          'kenworth'
                        ).toFixed(2) || '0.00'
                      : 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={3}>
                  <NormalText>{`Comisión Inter/Ford: $${
                    getPuestoById(worker.trabajador.id) === '8'
                      ? getComisionsLogisticWorker(
                          worker.trabajador.id,
                          worker.viajes.camion,
                          'camion'
                        ).toFixed(2) || '0.00'
                      : 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>{`Comisión Ford 2014: $${
                    getPuestoById(worker.trabajador.id) === '8'
                      ? getComisionsLogisticWorker(
                          worker.trabajador.id,
                          worker.viajes.doble,
                          'doble'
                        ).toFixed(2) || '0.00'
                      : 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={2}>
                  <NormalText>{`Comisión Nissan: $${
                    getPuestoById(worker.trabajador.id) === '8'
                      ? getComisionsLogisticWorker(
                          worker.trabajador.id,
                          worker.viajes.nissan,
                          'nissan'
                        ).toFixed(2) || '0.00'
                      : 0
                  }`}</NormalText>
                </Grid>
                <Grid item md={3}>
                  <NormalText>{`Comisión Piezas Cargadas: $${
                    getComisionsLogisticWorker(
                      worker.trabajador.id,
                      worker.pzsCargadas,
                      'pieza'
                    ).toFixed(2) || '0.00'
                  }`}</NormalText>
                </Grid>
                {/* Salarios */}
                <Grid container item md={12}>
                  <Grid item md={6} />
                  <Grid item md={3}>
                    <NormalText>Salario Semanal Bruto</NormalText>
                  </Grid>
                  <Grid item md={1}>
                    <NormalText>=</NormalText>
                  </Grid>
                  <Grid item md={2}>
                    <NormalText>
                      ${parseFloat(worker.salarioSemanal).toFixed(2) || '0.00'}
                    </NormalText>
                  </Grid>
                  {/** */}
                  <Grid item md={6} />
                  <Grid item md={3}>
                    <NormalText>Retenciones</NormalText>
                  </Grid>
                  <Grid item md={1}>
                    <NormalText>=</NormalText>
                  </Grid>
                  <Grid item md={2}>
                    <NormalText>
                      ${getRetencionesById(worker.trabajador.id) || '0.00'}
                    </NormalText>
                  </Grid>
                  {/** */}
                  <Grid item md={6} />
                  <Grid item md={3}>
                    <NormalText>Salario Semanal Neto</NormalText>
                  </Grid>
                  <Grid item md={1}>
                    <NormalText>=</NormalText>
                  </Grid>
                  <Grid item md={2}>
                    <NormalText>
                      ${parseFloat(worker.salarioNeto).toFixed(2) || '0.00'}
                    </NormalText>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={8} />
              <Grid item md={4}>
                <GenerateButton
                  variant="contained"
                  startIcon={<Calculate />}
                  onClick={() => {
                    handleGenerateSalary(worker, index)
                  }}
                  disabled={status}
                >
                  Generar Salario
                </GenerateButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {!status ? (
          <input
            id="submit"
            className="btn"
            type="submit"
            name="submit"
            disabled={status}
          />
        ) : (
          <Grid container spacing={1}>
            <Grid item md={12} onClick={() => handleGenerateReport()}>
              <ReportButton>Generar Reporte General de Area</ReportButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </div>
  )
}

export default LogisticForm
