import { getDatabase, onValue, push, ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'
import { database } from '../configs/firebase'

export const useNominas = () => {
  const [nominas, setNominas] = useState([])

  useEffect(() => {
    onValue(
      ref(database, 'nominas/'),
      (snapshot) => {
        const nominasList = []
        snapshot.forEach((item) => {
          const nominaItem = {
            id: item.key,
            ...item.val(),
          }

          nominasList.push(nominaItem)
        })
        setNominas(nominasList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [nominas]
}

export const getNominas = () => {
  try {
    const nominas = []
    onValue(ref(database, 'nominas/'), (snapshot) => {
      snapshot.forEach((item) => {
        const nominaItem = {
          id: item.key,
          ...item.val(),
        }
        nominas.push(nominaItem)
      })
    })
    return nominas
  } catch (error) {
    console.log(error)
  }
}

export const createNomina = (values, initialDate, finalDate) => {
  const db = getDatabase()

  let descripcion = `NÓMINA SEMANAL DE DÍA ${new Date(
    initialDate
  ).toLocaleDateString()} AL DÍA ${new Date(finalDate).toLocaleDateString()}`

  values.initialDate = new Date(initialDate).toISOString()
  values.finalDate = new Date(finalDate).toISOString()

  values.descripcion = descripcion
  values.status = '1'

  push(ref(db, 'nominas/'), {
    ...values,
  })
}

export const updateNomina = (id, initialDate, finalDate) => {
  const db = getDatabase()

  let descripcion = `NÓMINA SEMANAL DE DÍA ${new Date(
    initialDate
  ).toLocaleDateString()} AL DÍA ${new Date(finalDate).toLocaleDateString()}`

  update(ref(db, `nominas/${id}/`), {
    initialDate: initialDate,
    finalDate: finalDate,
    descripcion: descripcion,
  })
}

export const createProductionNomina = (values, id) => {
  const db = getDatabase()

  update(ref(db, `nominas/${id}/`), {
    1: { ...values },
  })
}

export const createPaletNomina = (values, id) => {
  const db = getDatabase()

  update(ref(db, `nominas/${id}/`), {
    2: { ...values },
  })
}

export const createLogisticNomina = (values, id) => {
  const db = getDatabase()

  update(ref(db, `nominas/${id}/`), {
    4: { ...values, viajes: { ...values.viajes } },
  })
}

export const createSalesNomina = (values, id) => {
  const db = getDatabase()

  update(ref(db, `nominas/${id}/`), {
    5: { ...values },
  })
}

export const createAdminNomina = (values, id) => {
  const db = getDatabase()

  update(ref(db, `nominas/${id}/`), {
    6: { ...values },
  })
}
