import { getDatabase, onValue, push, ref, update } from 'firebase/database'
import { useLayoutEffect } from 'react'
import { useState } from 'react'
import { database } from '../configs/firebase'

export const FetchPay = () => {
  const [payments, setPayments] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'caja/'),
      (snapshot) => {
        const paymentsList = []
        snapshot.forEach((item) => {
          const fechaStr = item.val().fecha
          const [day, month, year] = fechaStr.split('/').map(Number)
          const fechaDate = new Date(year, month - 1, day)
            .toISOString()
            .split('T')[0]

          const paymentsItem = {
            id: item.key,
            importe: item.val().importe,
            ticket: item.val().ticket,
            fecha: fechaDate,
            tipo_pago: item.val().tipo_pago,
          }
          paymentsList.push(paymentsItem)
        })
        setPayments(paymentsList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [payments]
}

export const getPay = () => {
  try {
    let paymentsList = []
    onValue(ref(database, 'caja/'), (snapshot) => {
      snapshot.forEach((item) => {
        const paymentsItem = {
          id: item.key,
          importe: parseFloat(item.val().importe),
          ticket: item.val().ticket,
          fecha: item.val().fecha,
          tipo_pago: item.val().tipo_pago,
        }
        paymentsList.push(paymentsItem)
      })
    })
    return paymentsList
  } catch (error) {
    return {
      isSucess: false,
      errorMessage: error.message,
    }
  }
}

export const addPay = (importe, ticket, tipo_pago) => {
  const db = getDatabase()

  push(ref(db, 'caja'), {
    fecha: new Date().toLocaleDateString(),
    importe: importe,
    ticket: ticket,
    tipo_pago: tipo_pago,
  })

  update(ref(db, `fletes/${ticket}/`), {
    estado: 'Pagada',
  })
}
