import { useState } from 'react'
import { Form } from '../../UI/Form'
import { Grid, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'
import Swal from 'sweetalert2'
import { filterCountTickets } from '../../../helpers/cashFunctions'
import { generatePDFCash, generateXLXSCash } from '../../../services/reports'

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'start',
  },
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

export const CountForm = ({ setOpenPopup }) => {
  const classes = useStyles()

  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (date2 !== '') {
      console.log(filterCountTickets(date1, date2))
      Swal.fire({
        title: 'Corte generado con éxito',
        showCancelButton: true,
        cancelButtonText: 'Descargar archivo XLSX',
        confirmButtonText: 'Descargar archivo PDF',
        cancelButtonColor: 'green',
        confirmButtonColor: 'red',
        text: '¿Cómo deseas descargar el archivo?',
      }).then((result) => {
        if (result.isConfirmed) {
          // Acción para descargar el archivo PDF
          generatePDFCash(filterCountTickets(date1, date2), date1, date2)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Acción para descargar el archivo XLSX
          //descargarArchivoXLSX();
          generateXLXSCash(
            filterCountTickets(date1, date2),
            `Corte de Caja del ${new Date(
              date1
            ).toLocaleDateString()} al ${new Date(
              date2
            ).toLocaleDateString()}.xlsx`
          )
        }
      })
      setOpenPopup(false)
      setDate1('')
      setDate2('')
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes de llenar todos los campos',
        customClass: {
          container: 'my-swal',
        },
        icon: 'info',
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <NormalText className={classes.gridItem}>Fecha Inicial</NormalText>
          <LocalizationProvider
            adapterLocale={esLocale}
            dateAdapter={DateFnsUtils}
          >
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date1}
              onChange={(date) => setDate1(date)}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    ></input>
                  </div>
                </Box>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={6}>
          <NormalText className={classes.gridItem}>Fecha Final</NormalText>
          <LocalizationProvider
            adapterLocale={esLocale}
            dateAdapter={DateFnsUtils}
          >
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date2}
              onChange={(date) => setDate2(date)}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    ></input>
                  </div>
                </Box>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <input id="submit" className="btn" type="submit" name="submit" />
    </Form>
  )
}
