import React, { useState } from 'react'

import { useForm, Form } from '../UI/Form'

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { Box } from '@mui/material'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'

import Swal from 'sweetalert2'
import Controls from '../UI/Controls'
import { statusReportService } from '../../services/reports'

const initialValues = { unidad: '' }

export const unidades = [
  { id: 'Todas las Unidades', title: 'Reporte General' },
  { id: 'Kenworth', title: 'Kenworth' },
  { id: 'International', title: 'International' },
  { id: 'Camión Ford', title: 'Camión Ford' },
  { id: 'Camioneta Ford', title: 'Camioneta Ford' },
  { id: 'Nissan 2014', title: 'Nissan 2014' },
]

export const StatusReport = ({ setOpenPopup }) => {
  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')

  const { values, handleInputChange } = useForm(initialValues)

  const { unidad } = values

  const handleSubmit = (e) => {
    e.preventDefault()
    if (date2 !== '') {
      statusReportService(date1, date2, unidad)
      setOpenPopup(false)
      setDate1('')
      setDate2('')
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes de llenar todos los campos',
        customClass: {
          container: 'my-swal',
        },
        icon: 'info',
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="large-group">
        <div className="small-group">
          <label>Unidad</label>
          <Controls.Select
            id="unidad"
            type="text"
            name="unidad"
            value={unidad}
            onChange={handleInputChange}
            options={unidades}
            MenuProps={{
              disablePortal: true,
            }}
          />
        </div>
        <div className="small-group">
          <label>Fecha inicial</label>
          <LocalizationProvider adapterLocale={es} dateAdapter={DateFnsUtils}>
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date1}
              onChange={(date) => setDate1(date)}
              renderInput={({ inputRef, inputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    />
                  </div>
                </Box>
              )}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="small-group">
          <label>Fecha final</label>
          <LocalizationProvider adapterLocale={es} dateAdapter={DateFnsUtils}>
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date2}
              onChange={(date) => {
                if (date.getTime() < date1.getTime()) {
                  Swal.fire({
                    title: 'Error',
                    text: 'La fecha final no puede ser menor a la fecha inicial',
                    customClass: {
                      container: 'my-swal',
                    },
                    icon: 'info',
                  })
                } else {
                  setDate2(date)
                }
              }}
              renderInput={({ inputRef, inputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    />
                  </div>
                </Box>
              )}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="special-group">
          <button className="btn" onClick={handleSubmit}>
            Generar Reporte
          </button>
        </div>
      </div>
    </Form>
  )
}
