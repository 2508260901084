import Controls from '../../UI/Controls'
import { Form, useForm } from '../../UI/Form'

import Swal from 'sweetalert2'
import { Button } from '@mui/material'

import { addPay } from '../../../services/cash'

import { ticketSelectCashRegister } from '../../../helpers/cashFunctions'

const initialValues = {
  importe: '',
  ticket: '',
  tipo_pago: '',
}

export const CashForm = ({ setOpenPopup }) => {
  const tickets = ticketSelectCashRegister()
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { importe, ticket, tipo_pago } = values

  const options = [
    { id: 'De Contado', title: 'De Contado' },
    { id: 'Por Transferencia', title: 'Por Transferencia' },
    { id: 'Con Tarjeta', title: 'Con Tarjeta' },
  ]

  const validate = () => {
    let temp = {}

    temp.importe =
      (importe || '').length === 0
        ? 'Este campo es requerido'
        : !/^[0-9\s]*$/.test(importe)
        ? 'Sólo se aceptan números'
        : importe === '0'
        ? 'El importe no puede ser $0'
        : ''
    temp.ticket = (ticket || '').length === 0 ? 'Este campo es requerido' : ''
    temp.tipo_pago =
      (tipo_pago || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      Swal.fire({
        icon: 'question',
        title: '¿Estas seguro de registrar este pago?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí, estoy seguro',
        denyButtonText: 'Cancelar',
        confirmButtonColor: '#28A745',
        customClass: {
          container: 'my-swal',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          addPay(importe, ticket, tipo_pago)
          Swal.fire({
            title: 'Hecho',
            icon: 'success',
            text: 'Pago registrado exitosamente',
            position: 'top-end',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 1000,
            customClass: {
              container: 'my-swal',
            },
          })
        } else if (result.isDenied) {
          Swal.fire({
            title: 'Operación abortada',
            icon: 'info',
            position: 'top-end',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 1000,
            customClass: {
              container: 'my-swal',
            },
          })
        }
        resetForm()
        setValues({})
        setOpenPopup(false)
      })
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form>
        <div className="large-group">
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="fecha">Fecha</label>
            <Controls.Input
              id="fecha"
              type="text"
              name="fecha"
              disabled
              value={new Date().toLocaleDateString()}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="ticket">* Ticket</label>
            <Controls.Select
              id="ticket"
              type="text"
              name="ticket"
              value={ticket}
              options={tickets}
              onChange={handleInputChange}
              MenuProps={{
                disablePortal: true,
              }}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="importe">* Importe</label>
            <Controls.Input
              id="importe"
              type="text"
              name="importe"
              value={importe}
              onChange={handleInputChange}
              error={errors.importe}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="tipo_pago">* Tipo de Pago</label>
            <Controls.Select
              id="tipo_pago"
              type="text"
              name="tipo_pago"
              value={tipo_pago}
              onChange={handleInputChange}
              options={options}
              MenuProps={{
                disablePortal: true,
              }}
            />
            {errors.tipo_pago !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.tipo_pago}
              </label>
            )}
          </div>
          <div className="special-group">
            <Button
              onClick={handleSubmit}
              sx={{
                backgroundColor: '#00544e',
                color: 'white',
                width: '100%',
                borderRadius: '20px',
                transition: '0.3s all ease',
                '&:hover': {
                  backgroundColor: '#009696',
                  border: '1px solid #00544e',
                },
              }}
            >
              Guardar Pago
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
