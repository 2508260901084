export const nomina = {
  1: {
    lunes: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    martes: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    miercoles: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    jueves: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    viernes: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    sabado: {
      trabajadores: {
        1: {
          id: '',
          salarioGenerado: '',
        },
        2: {
          id: '',
          salarioGenerado: '',
        },
        3: {
          id: '',
          salarioGenerado: '',
        },
        4: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto: '',
      cantidad: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    salarioSemanal: {
      1: '',
      2: '',
      3: '',
      4: '',
    },
  },
  2: {
    lunes: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    martes: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    miercoles: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    jueves: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    viernes: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    sabado: {
      trabajadores: {
        5: {
          id: '',
          salarioGenerado: '',
        },
        6: {
          id: '',
          salarioGenerado: '',
        },
        7: {
          id: '',
          salarioGenerado: '',
        },
      },
      producto1: '',
      cantidad1: '',
      producto2: '',
      cantidad2: '',
      salarioPDia: '',
      horaSalarioPDia: '',
    },
    salarioSemanal: {
      5: '',
      6: '',
      7: '',
    },
  },
  3: {
    choferes: {},
    ayudantes: {},
  },
  4: {},
  5: {
    comisiones: {
      cpc30r: '',
      mort: '',
      var38: '',
      block: '',
      tabicon: '',
      poste: '',
    },
    vendedores: {},
  },
  6: {},
  descripcion: '',
  status: '',
  initialDate: '',
  finalDate: '',
}
