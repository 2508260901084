import { FetchWorkers, getWorkers } from '../services/workers'

export const selectProductionWorkers = () => {
  const [workers] = FetchWorkers()

  const filterOnlyProductionWorkers = workers.filter(
    (worker) =>
      worker.area === '1' || worker.area === '2' || worker.area === '4'
  )

  const workersProductionList = []

  filterOnlyProductionWorkers.forEach(function ({ id, nombre }) {
    const dataSelect = {
      id,
      title: nombre,
    }
    workersProductionList.push(dataSelect)
  })

  return workersProductionList
}

export const selectPaletWorkers = () => {
  const workers = getWorkers()

  const filterOnlyPaletWorkers = workers.filter(
    (worker) => worker.area === '2' || worker.area === '4'
  )

  const workersPaletList = []

  filterOnlyPaletWorkers.forEach(function ({ id, nombre }) {
    const dataSelect = {
      id,
      title: nombre,
    }
    workersPaletList.push(dataSelect)
  })

  return workersPaletList
}

export const selectLogisticWorkers = () => {
  const [workers] = FetchWorkers()

  const filterOnlyLogisticWorkers = workers.filter(
    (worker) => worker.area === '3' || worker.area === '4'
  )

  const workersLogisticList = []

  filterOnlyLogisticWorkers.forEach(function ({ id, nombre }) {
    const dataSelect = {
      id,
      title: nombre,
    }
    workersLogisticList.push(dataSelect)
  })

  return workersLogisticList
}

export const selectSalesWorkers = () => {
  const [workers] = FetchWorkers()

  const filterOnlySalesWorkers = workers.filter((worker) => worker.area === '5')

  const workersSalesList = []

  filterOnlySalesWorkers.forEach(function ({ id, nombre }) {
    const dataSelect = {
      id,
      title: nombre,
    }
    workersSalesList.push(dataSelect)
  })

  return workersSalesList
}

export const selectAdministrationWorkers = () => {
  const [workers] = FetchWorkers()

  const filterOnlyAdministrationWorkers = workers.filter(
    (worker) => worker.area === '6'
  )

  const workersAdministrationList = []

  filterOnlyAdministrationWorkers.forEach(function ({ id, nombre }) {
    const dataSelect = {
      id,
      title: nombre,
    }
    workersAdministrationList.push(dataSelect)
  })

  return workersAdministrationList
}

export const filterLogisticWorkers = () => {
  const [workers] = FetchWorkers()

  const filterOnlyLogisticWorkers = workers.filter(
    (worker) =>
      worker.area === '2' || worker.area === '3' || worker.area === '4'
  )

  const workersLogisticList = []

  filterOnlyLogisticWorkers.forEach(function ({ id, nombre, puesto }) {
    const dataSelect = {
      nombre: nombre,
      puesto: getPuesto(puesto),
      id: id,
    }
    workersLogisticList.push(dataSelect)
  })

  return workersLogisticList
}

export const getWorkersByArea = (area) => {
  const workers = getWorkers()

  const filterWorkersByArea = workers.filter((worker) => worker.area === area)

  const workersList = []

  filterWorkersByArea.forEach(function ({ id, nombre, puesto }) {
    const dataSelect = {
      nombre: nombre,
      puesto: getPuesto(puesto),
      id: id,
    }
    workersList.push(dataSelect)
  })

  return workersList
}

export const getWorkerById = (id) => {
  const workers = getWorkers()

  const filterWorkerById = workers.find((worker) => worker.id === id)

  return filterWorkerById
}

export const getProducts = () => {
  const [workers] = FetchWorkers()

  const filterOnlyProductionChief = workers.filter(
    (worker) => worker.area === '1' && worker.puesto === '1'
  )

  const temp = filterOnlyProductionChief[0]

  const products = []

  // Accede al nodo `variables` y verifica que exista
  if (temp && temp.variables) {
    // Usa Object.entries para recorrer las claves y valores de `variables`
    Object.entries(temp.variables).forEach(([key, value]) => {
      // Comprueba si el campo `importe` es menor a 20
      if (
        value.importe <= 20 &&
        !/diario|retenciones|retencion|devolucion|devoluciones|bono/i.test(
          value.variable
        )
      ) {
        products.push({
          id: value.variable,
          title: value.variable,
        }) // Agrega el nodo a `products` si cumple la condición
      }
    })
  }

  return products
}

export const getPaletProducts = () => {
  const [workers] = FetchWorkers()

  const filterPaletWorker = workers.find((worker) => worker.area === '2')

  const products = []

  if (filterPaletWorker && filterPaletWorker.variables) {
    Object.entries(filterPaletWorker.variables).forEach(([key, value]) => {
      if (
        !/diario|retenciones|retencion|devolucion|devoluciones|bono/i.test(
          value.variable
        )
      ) {
        products.push({
          id: value.variable,
          title: value.variable,
        }) // Agrega el nodo a `products` si cumple la condición
      }
    })
  }

  return products
}

export const getArea = (id) => {
  const workers = getWorkers()

  const workerData = workers.find((worker) => worker.id === id)

  return workerData.area
}

export const getPuesto = (puesto) => {
  switch (puesto) {
    case '1':
      return 'Encargado de Producción'
    case '2':
      return 'Asistente de Producción 1'
    case '3':
      return 'Transfer de Producción'
    case '4':
      return 'Asistente de Producción 2'
    case '5':
      return 'Paletizador 1'
    case '6':
      return 'Paletizador 2'
    case '7':
      return 'Transfer Paletizado'
    case '8':
      return 'Chofer'
    case '9':
      return 'Ayudante de Entregas'
    case '10':
      return 'Encargado de Despacho'
    case '11':
      return 'Auxiliar de Despacho'
    case '12':
      return 'Vendedor'
    case '13':
      return 'Auxiliar Contable'
    case '14':
      return 'Auxiliar Administrativo'
    case '15':
      return 'Gerente'
    case '16':
      return 'Velador'
    default:
      return 'error'
  }
}

export const getRetencionesById = (id) => {
  const workers = getWorkers()

  const worker = workers.find((worker) => worker.id === id)

  // Si no existe el trabajador o no tiene variables, retornamos 0
  if (!worker || !worker.variables) {
    return 0
  }

  // Buscamos la primera variable con importe negativo
  let retencion = 0
  Object.entries(worker.variables).forEach(([key, value]) => {
    if (value.importe !== undefined && value.importe < 0) {
      retencion = value.importe
    }
  })
  return retencion
}

export const getComisionPzs = (id) => {
  const workers = getWorkers()

  const worker = workers.find((worker) => worker.id === id)

  let comision = 0

  if (worker && worker.variables) {
    Object.entries(worker.variables).forEach(([key, value]) => {
      if (
        value.variable.toLowerCase().includes('pieza') ||
        value.variable.toLowerCase().includes('piezas')
      ) {
        comision = value.importe
      }
    })
  }
  return comision
}

export const getComision = (producto, id) => {
  const workers = getWorkers()

  if (!workers || workers.length === 0) {
    return null
  }

  const worker = workers.find((worker) => worker.id === id)

  // Si no existe el trabajador o no tiene variables, retornamos 0
  if (!worker || !worker.variables) {
    return 0
  }

  let comision = 0
  Object.entries(worker.variables).forEach(([key, value]) => {
    if (value.importe !== undefined && value.variable === producto) {
      comision = value.importe
    }
  })
  return comision
}

export const getSueldoDiarioById = (id) => {
  const workers = getWorkers()

  if (!workers || workers.length === 0) {
    return null
  }

  const worker = workers.find((worker) => worker.id === id)

  // Si no existe el trabajador o no tiene variables, retornamos 0
  if (!worker || !worker.variables) {
    return 0
  }

  let sueldo = 0
  Object.entries(worker.variables).forEach(([key, value]) => {
    if (
      (value.importe !== undefined &&
        value.variable.toLowerCase().includes('salario')) ||
      value.variable.toLowerCase().includes('sueldo')
    ) {
      sueldo = value.importe
    }
  })

  return sueldo
}

export const getPuestoById = (id) => {
  const workers = getWorkers()

  // Verifica si `workers` está listo
  if (!workers || workers.length === 0) {
    return null // O cualquier valor de carga temporal
  }

  const worker = workers.find((worker) => worker.id === id)
  return worker ? worker.puesto : 'No encontrado'
}

export const getWorkerNameById = (id) => {
  const workers = getWorkers()

  const workerData = workers.find((worker) => worker.id === id)

  return workerData.nombre
}

export const getSelectBonos = (id) => {
  const workers = getWorkers()

  const filterBonos = workers.find((worker) => worker.id === id)

  const bonos = []

  if (filterBonos && filterBonos.variables) {
    Object.entries(filterBonos.variables).forEach(([key, value]) => {
      if (value.variable.toLowerCase().includes('bono')) {
        bonos.push({
          id: value.variable,
          title: value.variable,
          value: value.importe,
        })
      }
    })
  }
  return bonos
}

export const getPuestoByFirstName = (name) => {
  const workers = getWorkers()

  // Verifica si `workers` está listo
  if (!workers || workers.length === 0) {
    return null // O cualquier valor de carga temporal
  }

  const worker = workers.find((worker) =>
    worker.nombre.toLowerCase().includes(name)
  )
  return worker ? worker.puesto : 'No encontrado'
}
