import { Button, Grid, Typography } from '@mui/material'
import { Form } from '../../UI/Form'
import Controls from '../../UI/Controls'
import { styled } from '@mui/system'
import { makeStyles } from '@material-ui/core'
import { Calculate } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  getProducts,
  selectProductionWorkers,
} from '../../../helpers/workersFunctions'
import {
  calculateProductionSalaries,
  checkStatus,
  getStatus,
} from '../../../helpers/nominaFunctions'
import { createProductionNomina } from '../../../services/nomina'
import { productionReport } from '../../../services/reports'

const useStyles = makeStyles(() => ({
  inputTitle: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dateInputTitle: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'end',
  },
  salaryItem: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Subtitle = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1.2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const GenerateButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: 'orange',
  border: '1px solid darkorange',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: 'darkorange',
    border: '1px solid orange',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const ReportButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: '#00544e',
  border: '1px solid #009696',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: '#009696',
    border: '1px solid #00544e',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const ProductionForm = ({ setObject, object, setOpenPopup, setId, id }) => {
  let workers = selectProductionWorkers()
  let products = getProducts()

  const [tempObject, setTempObject] = useState(null)
  const [status, setStatus] = useState(false)

  const daysOfWeek = [
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
  ]

  const classes = useStyles()

  useEffect(() => {
    setStatus(checkStatus(getStatus(id)))
    setTempObject(object || {})
  }, [object])

  const resetData = () => {
    setId('')
    setObject(object)
  }

  const handleInputChange = (day, field, value) => {
    setTempObject((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [field]: value,
      },
    }))
  }

  const handleWorkerChange = (day, index, field, value) => {
    setTempObject((prevState) => {
      return {
        ...prevState,
        [day]: {
          ...prevState[day],
          trabajadores:
            prevState[day]?.trabajadores?.map((worker, i) => {
              // Si encontramos el índice correspondiente, actualizamos el trabajador
              if (i === index + 1) {
                // Si el trabajador es `null`, inicializamos el objeto con el campo actualizado
                const updatedWorker = worker
                  ? { ...worker, [field]: value }
                  : { [field]: value }
                return updatedWorker
              }
              return worker // Si no es el trabajador a actualizar, lo dejamos igual
            }) || [], // Si no hay trabajadores, dejamos el arreglo vacío
        },
      }
    })
  }

  const handleGenerateSalary = (dayData) => {
    const updatedDay = calculateProductionSalaries(
      tempObject[dayData],
      products
    )
    setTempObject((prevState) => ({
      ...prevState,
      [dayData]: updatedDay,
    }))
  }

  const handleGenerateReport = () => {
    productionReport(object, '1')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createProductionNomina(tempObject, id)

    resetData()
    Swal.fire('Hecho', 'Area actualizada con éxito', 'success')
    setOpenPopup(false)
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {daysOfWeek.map((day) => (
            <>
              {/* Título de producción del día */}
              <Grid item md={12} key={`${day}-title`}>
                <Subtitle>
                  Producción Día {day.charAt(0).toUpperCase() + day.slice(1)}
                </Subtitle>
              </Grid>

              {/* Selección de producto */}
              <Grid item xs={12} md={1} className={classes.inputTitle}>
                <NormalText>Producto</NormalText>
              </Grid>
              <Grid item xs={12} md={2}>
                <Controls.Select
                  name={`${day}-producto`}
                  value={tempObject?.[day]?.producto || '-'}
                  onChange={(event) =>
                    handleInputChange(day, 'producto', event.target.value)
                  }
                  options={[
                    { id: '-', title: 'Selecciona una opción' },
                    ...products,
                  ]}
                  disabled={status}
                />
              </Grid>

              {/* Cantidad */}
              <Grid item xs={12} md={1} className={classes.inputTitle}>
                <NormalText>Cantidad</NormalText>
              </Grid>
              <Grid item xs={12} md={2}>
                <Controls.Input
                  name={`${day}-cantidad`}
                  value={tempObject?.[day]?.cantidad || '-'}
                  onChange={(event) =>
                    handleInputChange(day, 'cantidad', event.target.value)
                  }
                  disabled={status}
                />
              </Grid>

              {/* Salario por día */}
              <Grid item xs={12} md={1} className={classes.inputTitle}>
                <label>Salario p/Día</label>
              </Grid>
              <Grid item xs={12} md={2}>
                <Controls.Select
                  name={`${day}-salarioPDia`}
                  value={tempObject?.[day]?.salarioPDia || '-'}
                  onChange={(event) =>
                    handleInputChange(day, 'salarioPDia', event.target.value)
                  }
                  options={[
                    { id: '-', title: 'Selecciona una opción' },
                    { id: '0', title: 'Sí' },
                    { id: '1', title: 'No' },
                  ]}
                  disabled={status}
                />
              </Grid>

              {/* Horas Pagadas por Día */}
              <Grid item xs={12} md={1} className={classes.inputTitle}>
                <label>Horas de Sueldo p/Día</label>
              </Grid>
              <Grid item xs={12} md={2}>
                <Controls.Input
                  name={`${day}-horaSalarioPDia`}
                  value={tempObject?.[day]?.horaSalarioPDia || 0}
                  onChange={(event) =>
                    handleInputChange(
                      day,
                      'horaSalarioPDia',
                      event.target.value
                    )
                  }
                  disabled={status}
                />
              </Grid>

              {/* Trabajadores */}
              <Grid item md={8}>
                <NormalText>Trabajadores</NormalText>
              </Grid>
              <Grid item md={4}>
                <NormalText>Salario Generado</NormalText>
              </Grid>

              {/* Trabajadores específicos */}
              {[
                'Encargado de Producción',
                'Transfer de Producción',
                'Asistente de Producción 1',
                'Asistente de Producción 2',
              ].map((role, index) => (
                <>
                  <Grid
                    item
                    md={3}
                    className={classes.inputTitle}
                    key={`${day}-${role}`}
                  >
                    <label>{role}</label>
                  </Grid>
                  <Grid item md={5}>
                    <Controls.Select
                      name={`${day}-trabajadores-${index + 1}-id`} // Aquí pasamos el índice del trabajador
                      value={
                        tempObject?.[day]?.trabajadores?.[index + 1]?.id || '-'
                      }
                      onChange={(event) =>
                        handleWorkerChange(day, index, 'id', event.target.value)
                      }
                      options={[
                        { id: '-', title: 'Selecciona una opción' },
                        ...workers,
                      ]}
                      disabled={status}
                    />
                  </Grid>
                  <Grid item md={4} className={classes.salaryItem}>
                    <label>{`$${
                      (
                        parseFloat(
                          tempObject?.[day]?.trabajadores?.[index + 1]
                            ?.salarioGenerado
                        ) || 0
                      ).toFixed(2) || '0.00'
                    }`}</label>
                  </Grid>
                </>
              ))}

              {/* Botón para generar salario por día */}
              <Grid item md={8}></Grid>
              <Grid item xs={12} md={4}>
                <GenerateButton
                  variant="contained"
                  startIcon={<Calculate />}
                  onClick={() => {
                    handleGenerateSalary(day)
                  }}
                  disabled={status}
                >
                  Generar Salario para{' '}
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </GenerateButton>
              </Grid>
            </>
          ))}
        </Grid>
        {!status ? (
          <input
            id="submit"
            className="btn"
            type="submit"
            name="submit"
            disabled={status}
          />
        ) : (
          <Grid container spacing={1}>
            <Grid item md={12}>
              <ReportButton onClick={() => handleGenerateReport()}>
                Generar Reporte General de Area
              </ReportButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </div>
  )
}

export default ProductionForm
