import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TextField,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { Search } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { filterLogisticWorkers } from '../../helpers/workersFunctions'

const headCells = [
  { id: 'nombre', label: 'Nombre' },
  { id: 'puesto', label: 'Puesto' },
]

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    outline: '0',
    marginBottom: '20px',
    borderRadius: '20px',
    border: '0',
    backgroundColor: '#e9e9e9',
    fontSize: '14px',
    appearance: 'none',
    resize: 'none',
    padding: '2px',
  },
  input: {
    border: 'none',
    height: '2rem',
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
}))

export const processNombre = (nombreCompleto) => {
  if (!nombreCompleto || typeof nombreCompleto !== 'string') {
    throw new Error('El nombre proporcionado no es válido')
  }

  let nombre

  if (nombreCompleto.toLowerCase() === 'por definir') {
    // Si el nombre es "por definir", se asigna completo
    nombre = nombreCompleto
  } else {
    // Dividimos el nombre en palabras
    const partes = nombreCompleto.trim().split(/\s+/)

    if (partes.length === 1) {
      // Si solo hay una palabra, asignamos esa
      nombre = partes[0]
    } else if (partes.length === 3) {
      // Si hay exactamente 3 palabras, tomamos la primera
      nombre = partes[0]
    } else {
      // Si hay más de 3 palabras, tomamos las primeras dos
      nombre = partes.slice(0, 2).join(' ')
    }
  }

  return nombre
}

export const WorkerSelect = ({
  person,
  setOpenPopup,
  setDriver,
  setHelper,
  setHelper2,
}) => {
  const drivers = filterLogisticWorkers()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(drivers, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar>
          <div className="medium-group" style={{ marginRight: '1vw' }}>
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
              }}
              placeholder="Buscar Trabajador"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((worker) => (
              <TableRow
                key={worker.id}
                onClick={() => {
                  const nombreProcesado = processNombre(worker.nombre)
                  if (person === 'C') {
                    setDriver(nombreProcesado)
                  } else if (person === 'A') {
                    setHelper(nombreProcesado)
                  } else if (person === 'A2') {
                    setHelper2(nombreProcesado)
                  }
                  setOpenPopup(false)
                }}
              >
                <TableCell data-label="Nombre: ">{worker.nombre}</TableCell>
                <TableCell data-label="Puesto: ">{worker.puesto}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </div>
    </>
  )
}
