import { getDatabase, onValue, push, ref, remove, set } from 'firebase/database'
import { useEffect, useLayoutEffect, useState } from 'react'
import { database } from '../configs/firebase'

export const useWorkers = () => {
  const [workers, setWorkers] = useState([])

  useEffect(() => {
    onValue(
      ref(database, 'trabajadores/'),
      (snapshot) => {
        const workersList = []
        snapshot.forEach((item) => {
          const workerItem = {
            id: item.key,
            nombre: item.val().nombre,
            area: item.val().area,
            puesto: item.val().puesto,
            variablesArray: item.val().variables,
          }
          workersList.push(workerItem)
        })
        setWorkers(workersList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [workers]
}

export const getWorkers = () => {
  try {
    const workers = []
    onValue(ref(database, 'trabajadores/'), (snapshot) => {
      snapshot.forEach((item) => {
        const workerItem = {
          id: item.key,
          ...item.val(),
        }
        workers.push(workerItem)
      })
    })
    return workers
  } catch (error) {}
}

export const FetchWorkers = () => {
  const [workers, setWorkers] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'trabajadores/'),
      (snapshot) => {
        const workersList = []
        snapshot.forEach((item) => {
          const workerItem = {
            id: item.key,
            ...item.val(),
          }
          workersList.push(workerItem)
        })
        setWorkers(workersList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return [workers]
}

export const addWorker = (nombre, area, puesto, variablesArray) => {
  const db = getDatabase()

  push(ref(db, 'trabajadores'), {
    nombre: nombre,
    puesto: puesto,
    area: area,
    variables: variablesArray,
  })
}

export const updateWorker = (id, nombre, area, puesto, variables) => {
  const db = getDatabase()

  set(ref(db, `trabajadores/${id}`), {
    nombre: nombre,
    area: area,
    puesto: puesto,
    variables: variables,
  })
}

export const deleteWorker = (id) => {
  const db = getDatabase()

  remove(ref(db, `trabajadores/${id}`))
}
